/*
 * SPDX-FileCopyrightText: 2021 - 2023 Dusan Mijatovic (dv4all)
 * SPDX-FileCopyrightText: 2021 - 2023 dv4all
 * SPDX-FileCopyrightText: 2023 - 2024 Dusan Mijatovic (Netherlands eScience Center)
 * SPDX-FileCopyrightText: 2023 - 2024 Netherlands eScience Center
 *
 * SPDX-License-Identifier: Apache-2.0
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

/** ------------------------------------
  Default values
-------------------------------------**/

html {
  scroll-behavior: smooth;
}

body{
  font-size: 1rem;
  /* minimal with to have logo,
  add button, mobile menu and profile.
  NOTE ! when changing this value we need to update
  useDisableScrollLock hook in utils folder too.
  */
  min-width: 30rem;
}

#__next {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/* ---------------------------------
  Tailwind custom classes applied
-----------------------------------*/
/* NOTE!
1. SourceMaps are not working when using @apply
see bug: https://stackoverflow.com/questions/67333479/webpack-shows-incorrect-sourcemaps-when-using-tailwibd-directives

2. you cannot define class with the same name as tailwind utility classes you will get following error::after
error - ./node_modules/next/dist/build/webpack/loaders/css-loader/src/index.js??ruleSet[1].rules[2].oneOf[8].use[1]!./node_modules/next/dist/build/webpack/loaders/postcss-loader/src/index.js??ruleSet[1].rules[2].oneOf[8].use[2]!./styles/global.css
RangeError: Maximum call stack size exceeded

*/

@layer base {
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-xl;
  }
  a {
    @apply no-underline transition duration-100 cursor-pointer hover:text-accent;
  }

  body{
    @apply font-normal text-base-content;
  }
  /*
    FIX for default border color after default tailwind colors
    are removed from tailwind.config
  */
  *,
  :after,
  :before {
    border: 0 solid var(--rsd-base-300, #dcdcdc)
  }
}

/* ---------------------------------------
Tailwind overwrites
NOTE! the classes placed here come after the tailwind's autogenerated classes
-----------------------------------------*/
.prose {
  max-width: inherit;
  overflow: hidden;
}

.prose img{
  display: inline;
  margin: 0;
}

/* ---------------------
 MARKDOWN EDIT / PREVIEW
*/

#markdown-textarea:focus-visible {
  outline: none;
}

#markdown-textarea:disabled {
  cursor: not-allowed;
}

.nav-active{
  @apply border-b border-accent cursor-pointer
}

/* Fix for tailwind typography prose (markdown)
  single backticks in :before/:after are shown instead of grey box around the text.
  1. disabled this in ReactMarkdownWithSettings using classes prose-code:before:hidden prose-code:after:hidden
  2. added code formatting (from github styles)
*/
code{
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  white-space: break-spaces;
  background-color: var(--rsd-base-200, rgba(175, 184, 193, 0.2));
  border-radius: 6px;
}

/*
------------------------------------
CUSTOM STYLES
----------------------------------------
*/
@import url('./custom.css');
